import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import SEO from '../SEO';
import { urlFor } from '../utils/common';
import Navbar from '../components/navbar';
import BlockText from '../components/blockText/BlockText';
import { Footer } from '../components/footer';
import './plainBlogDetail.css';

const PlainPageDetail = ({ pageContext }) => {
  const [thisURL, setThisUrl] = useState('');

  useEffect(() => {
    setThisUrl(window.location.href);
  }, []);
  return (
    <div className="h-[calc(100dvh)] overflow-y-auto">
      <SEO
        lang={pageContext.lang}
        title={pageContext.title}
        metaDescription={pageContext.title}
        metaImage={urlFor(pageContext.mainImage).url()}
        type="website"
        postData={pageContext}
      />
      <Navbar />
      <div className="container mx-auto relative pt-16 px-8 lg:px-0 page-container">
        <div className="my-12">
          <h1 className="text-5xl font-bold text-primary">{pageContext.documentTitle}</h1>
        </div>
        <BlockText body={pageContext.content} url={thisURL} />
      </div>
      <Footer />
    </div>
  );
};
PlainPageDetail.propTypes = {
  pageContext: propTypes.object.isRequired,
};

export default PlainPageDetail;
